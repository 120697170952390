/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */

/* Apply the 'Indie Flower' font globally */
body {
  font-family: "Indie Flower", cursive;
}

/* Custom Animation for Mood Button */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(252, 211, 77, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 20px rgba(252, 211, 77, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(252, 211, 77, 0);
  }
}
.animate-pulse-custom {
  animation: pulse 2s infinite;
}

/* Button pressed effect */
.pressed {
  transform: scale(0.85); /* Made the button smaller */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Hide default Google Maps controls */
.gm-control-active {
  display: none !important;
}

/* Additional styles for multiple scores */
#scores-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.score-item {
  display: flex;
  align-items: center;
  gap: 4px;
}
